import React, { useContext, useEffect, useRef, useState } from "react";
import { MyTestContext } from "../TestPages/TestPageForLearning";
import { useQuery } from "react-query";
import { useCustomTranslation } from "../../Languages/useCustomTranslation";
import { G9Dictionary } from "../../Languages/G9Dictionary";
import foodSample from "./FoodSample.jpg";
import "./MenuData.scss";
import { motion, useScroll } from "framer-motion";

export enum G9EMenuTypeInfo {
  All,
  Appetizer,
  Breakfast,
  MainCourse,
  Pizza,
  Sandwiches,
  Burgers,
  Dessert,
  TeaAndHotDrinks,
  ColdDrink,
  ShakeAndFrappe,
  Coffee,
  // Additive,
}

export type MenuType = {
  MenuTypeEnum: G9EMenuTypeInfo;
  MenuTitle: string;
  MenuExteraInfo?: string[];
};

export type MenuItem = {
  Id: number;
  Name: string;
  Ingredient: string;
  Price: string;
  MenuTypeInfo: MenuType;
  Image?: string;
};

export function MenuData() {
  const { language, translator, translatorByStringKey } =
    useCustomTranslation();

  const AppetizerMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.Appetizer,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.Appetizer].toString(),
    MenuExteraInfo: [
      `افزودنی: نان اضافه(15)، روغن زیتون(50)، سس سزار(40)، سس پستو (40)، سس گوجه بادام(20)`,
    ],
  };

  const BreakfastMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.Breakfast,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.Breakfast].toString(),
    MenuExteraInfo: [
      `افزودنی: نان اضافه(15)، نیمرو اضافه(20)، سس گوجه بادام(20)`,
    ],
  };

  const MainCourseMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.MainCourse,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.MainCourse].toString(),
    MenuExteraInfo: [
      `افزودنی: پنیر پارمسان(50)، روغن زیتون(50)، سس پستو (40)، سس گوجه بادام(20)`,
    ],
  };

  const PizzaMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.Pizza,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.Pizza].toString(),
    MenuExteraInfo: [
      `افزودنی: پنیر پارمسان(50)، روغن زیتون(50)، سس پستو (40)، سس گوجه بادام(20)`,
    ],
  };

  const SandwichesMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.Sandwiches,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.Sandwiches].toString(),
    MenuExteraInfo: [
      `افزودنی: پنیر پارمسان(50)، روغن زیتون(50)، سس پستو (40)، سس گوجه بادام(20)`,
    ],
  };

  const BurgersMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.Burgers,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.Burgers].toString(),
    MenuExteraInfo: [
      `افزودنی: پتی اضافه گوشت(100)، پنیر اضافه(20)، پیاز کاراملی(20)، نیمرو(20)، قارچ(20)، روکولا(20)، سس گوجه بادام(20)`,
    ],
  };

  const DessertMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.Dessert,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.Dessert].toString(),
  };

  const TeaAndHotDrinksMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.TeaAndHotDrinks,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.TeaAndHotDrinks].toString(),
    MenuExteraInfo: [`افزودنی: عسل (30)، زعفران(20)، زنجبیل تازه(20)`],
  };

  const ShakeAndFrappeMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.ShakeAndFrappe,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.ShakeAndFrappe].toString(),
    MenuExteraInfo: [`افزودنی: قهوه سرد دم(30)، اسپرسو(40،50،60)`],
  };

  const ColdDrinkMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.ColdDrink,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.ColdDrink].toString(),
  };

  const CoffeeMenuType: MenuType = {
    MenuTypeEnum: G9EMenuTypeInfo.Coffee,
    MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.Coffee].toString(),
    MenuExteraInfo: [
      `دمای استاندارد شیر ۵۵ تا ۷۰ درجه سانتی گراد است. در صورت تمایل به دمای بالاتر به ما اطلاع دهید.`,
      `افزودنی: شیر بدون لاکتوز(15)، سیروپ(20)، شات اسپرسو اضافه(40،50،60)، شات شیر(25)`,
    ],
  };

  // const AdditiveMenuType: MenuType = {
  //   MenuTypeEnum: G9EMenuTypeInfo.Additive,
  //   MenuTitle: G9EMenuTypeInfo[G9EMenuTypeInfo.Additive].toString(),
  // };

  const MenuData: MenuItem[] = [

    // ################################# AppetizerMenuType #################################
    {
      Id: 78,
      Name: "سالاد سزار",
      Ingredient: "کاهو، کروتان نان خمیرترش، مرغ، پنیر پارمسان، سس سزار",
      Price: G9Dictionary.Price("279").Translate(),
      MenuTypeInfo: AppetizerMenuType,
    },
    {
      Id: 123,
      Name: "سوپ گوجه فرنگی",
      Ingredient: "گوجه فرنگی، فلفل دلمه رنگی، سبزیجات، تست پنیر",
      Price: G9Dictionary.Price("104").Translate(),
      MenuTypeInfo: AppetizerMenuType,
    },
    // {
    //   Id: 6,
    //   Name: "سوپ روز",
    //   Ingredient: "سوپ روز",
    //   Price: G9Dictionary.Price("75").Translate(),
    //   MenuTypeInfo: AppetizerMenuType,
    // },
    {
      Id: 77,
      Name: "نان سیر",
      Ingredient: "نان خمیرترش، روغن زیتون فرابکر مزه‌دار شده، پنیر موزارلا",
      Price: G9Dictionary.Price("97").Translate(),
      MenuTypeInfo: AppetizerMenuType,
    },
    {
      Id: 7,
      Name: G9Dictionary.GrilledCheeseSandwich().Translate(),
      Ingredient: G9Dictionary.GrilledCheeseSandwichIng().Translate(),
      Price: G9Dictionary.Price("97").Translate(),
      MenuTypeInfo: AppetizerMenuType,
    },
    {
      Id: 2,
      Name: G9Dictionary.Homs().Translate(),
      Ingredient: G9Dictionary.HomsIng().Translate(),
      Price: G9Dictionary.Price("104").Translate(),
      MenuTypeInfo: AppetizerMenuType,
    },
    {
      Id: 5,
      Name: "ماست و خیار یونانی (زاتزیکی)",
      Ingredient: G9Dictionary.ZatzikiIng().Translate(),
      Price: G9Dictionary.Price("90").Translate(),
      MenuTypeInfo: AppetizerMenuType,
    },
    {
      Id: 1,
      Name: "سیب زمینی ساده",
      Ingredient: "سیب زمینی سرخ شده",
      Price: G9Dictionary.Price("97").Translate(),
      MenuTypeInfo: AppetizerMenuType,
    },
    {
      Id: 70,
      Name: "سیب زمینی با دیپ پنیر",
      Ingredient: "سیب زمینی سرخ شده، دیپ پنیر",
      Price: G9Dictionary.Price("150").Translate(),
      MenuTypeInfo: AppetizerMenuType,
    },
    {
      Id: 69,
      Name: "سیب زمینی با مربا بیکن",
      Ingredient: "سیب زمینی سرخ شده، پیاز کاراملی، بیکن، فلفل هالوپینو",
      Price: G9Dictionary.Price("150").Translate(),
      MenuTypeInfo: AppetizerMenuType,
    },

    // ################################# BreakfastMenuType #################################

    {
      Id: 129,
      Name: "اوتمیل نارگیل و بری",
      Ingredient:
        "جو دوسر، دانه چیا، موز، شیر نارگیل، پوره شاهتوت و توت فرنگی، بادام درختی",
      Price: G9Dictionary.Price("190").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },
    {
      Id: 130,
      Name: "اوتمیل شکلات و کره بادام زمینی",
      Ingredient:
        "جو دوسر، دانه چیا، موز، شیر، شکلات واقعی، کره بادام زمینی، بادام زمینی",
      Price: G9Dictionary.Price("190").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },
    {
      Id: 131,
      Name: "فِرنچ تُست",
      Ingredient: "نان تست نوفه، کره بادام زمینی، مربا شاهتوت و توت فرنگی",
      Price: G9Dictionary.Price("160").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },
    {
      Id: 11,
      Name: "ساندویچ پنیر",
      Ingredient: "نان خمیرترش، پنیر، شوید، گوجه، خیار",
      Price: G9Dictionary.Price("90").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },
    {
      Id: 60,
      Name: "عدسی",
      Ingredient: "عدس، نان خمیرترش",
      Price: G9Dictionary.Price("90").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },
    // {
    //   Id: 59,
    //   Name: "نرگسی",
    //   Ingredient: "تخم مرغ، اسفناج، نان خمیرترش",
    //   Price: G9Dictionary.Price("135").Translate(),
    //   MenuTypeInfo: BreakfastMenuType,
    // },
    {
      Id: 8,
      Name: "شَکشوکا",
      Ingredient: "تخم مرغ، سس دلمه کبابی، چیمی چوری، نان خمیرترش",
      Price: G9Dictionary.Price("150").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },
    {
      Id: 107,
      Name: "املت ایرانی",
      Ingredient: "سس گوجه، تخم مرغ، پنیر فتا، ریحان",
      Price: G9Dictionary.Price("140").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },
    {
      Id: 108,
      Name: "املت سوسیس",
      Ingredient: "سوسیس 90%، تخم مرغ، سالاد سبز",
      Price: G9Dictionary.Price("182").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },
    {
      Id: 75,
      Name: "بشقاب سوسیس",
      Ingredient:
        "هات داگ %90، بیکن گوساله، سیب زمینی سرخ شده، تخم مرغ، گوجه گیلاسی، نان چاباتا",
      Price: G9Dictionary.Price("295").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },
    {
      Id: 109,
      Name: "پیتزا تخم مرغ",
      Ingredient: "تخم مرغ، بیکن، پنیر پارمسان، روکولا",
      Price: G9Dictionary.Price("253").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },
    {
      Id: 12,
      Name: "پنکیک با سس کارامل و موز",
      Ingredient: "پنکیک با سس کارامل و موز",
      Price: G9Dictionary.Price("165").Translate(),
      MenuTypeInfo: BreakfastMenuType,
    },

    // ################################# MainCourseMenuType #################################
    {
      Id: 13,
      Name: "پاستا پستو ریحان",
      Ingredient: "فتوچینی، سس پستو، مرغ",
      Price: G9Dictionary.Price("338").Translate(),
      MenuTypeInfo: MainCourseMenuType,
    },
    {
      Id: 117,
      Name: "پاستا پستو روسو",
      Ingredient: "فتوچینی، سس گوجه خشک و بادام درختی، گوشت گوساله ورقه‌ای شده",
      Price: G9Dictionary.Price("354").Translate(),
      MenuTypeInfo: MainCourseMenuType,
    },
    {
      Id: 76,
      Name: "پاستا سفید",
      Ingredient: "فتوچینی، سس بشامل، مرغ، قارچ، پنیر پارمسان",
      Price: G9Dictionary.Price("305").Translate(),
      MenuTypeInfo: MainCourseMenuType,
    },
    // {
    //   Id: 118,
    //   Name: "اسپاگتی وِگان",
    //   Ingredient:
    //     "اسپاگتی، سس کدو و تخم آفتابگردان، ریحان ایتالیایی، روغن زیتون فرابکر",
    //   Price: G9Dictionary.Price("150").Translate(),
    //   MenuTypeInfo: MainCourseMenuType,
    // },
    // {
    //   Id: 84,
    //   Name: "لازانیا مرغ و اسفناج",
    //   Ingredient: "لازانیا، سس بشامل، مرغ، اسفناج، پنیر موزارلا",
    //   Price: G9Dictionary.Price("215").Translate(),
    //   MenuTypeInfo: MainCourseMenuType,
    // },
    {
      Id: 110,
      Name: "لازانیا بلونز",
      Ingredient:
        "لازانیا، سس بلونز(گوشت گوساله چرخ کرده)، پنیر موزارلا، پنیر پارمسان",
      Price: G9Dictionary.Price("270").Translate(),
      MenuTypeInfo: MainCourseMenuType,
    },
    {
      Id: 19,
      Name: "بشقاب مرغ گریل",
      Ingredient: "مرغ گریل شده، پوره سیب زمینی، سالاد کلم، زاتزیکی",
      Price: G9Dictionary.Price("343").Translate(),
      MenuTypeInfo: MainCourseMenuType,
    },
    {
      Id: 20,
      Name: "بشقاب اشنیتزل مرغ",
      Ingredient: "مرغ سوخاری شده، پوره سیب زمینی، سالاد کلم، زاتزیکی",
      Price: G9Dictionary.Price("343").Translate(),
      MenuTypeInfo: MainCourseMenuType,
    },

    // ################################# PizzaMenuType #################################
    // {
    //   Id: 122,
    //   Name: "پیتزا بیکن و انجیر",
    //   Ingredient:
    //     "بیکن، انجیر، گردو، بلوچیز، پنیر استراچیاتلا، روکولا، ریحان",
    //   Price: G9Dictionary.Price("320").Translate(),
    //   MenuTypeInfo: PizzaMenuType,
    // },
    // {
    //   Id: 102,
    //   Name: "پیتزا بوقلمون و کرنبری(تاپینگ سرد)",
    //   Ingredient:
    //     "سینه بوقلمون فرآوری شده، سس کرنبری(شیرین)، پنیر استراچیاتلا، روکولا",
    //   Price: G9Dictionary.Price("274").Translate(),
    //   MenuTypeInfo: PizzaMenuType,
    // },
    {
      Id: 103,
      Name: "پیتزا بوقلمون و پستو",
      Ingredient:
        "روغن زیتون فرابکر مزه دار شده با ریحان، سینه بوقلمون فرآوری شده، سس پستو، پنیر موزارلا",
      Price: G9Dictionary.Price("332").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    {
      Id: 104,
      Name: "پیتزا استیک و قارچ",
      Ingredient: "سس بلوچیز، گوشت گوساله ورقه ای شده، قارچ، پنیر موزارلا",
      Price: G9Dictionary.Price("370").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    {
      Id: 55,
      Name: "پیتزا پولد بیف",
      Ingredient: "سس بشامل، گوشت گوساله آرام پز، بلوچیز، پنیر موزارلا",
      Price: G9Dictionary.Price("407").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    {
      Id: 25,
      Name: "پیتزا گوشت و سیب زمینی",
      Ingredient: "سس گوجه فرنگی، گوشت چرخ کرده، سیب زمینی، قارچ، پنیر موزارلا",
      Price: G9Dictionary.Price("295").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    {
      Id: 24,
      Name: "پیتزا بیکن و پیاز",
      Ingredient: "سس گوجه فرنگی، بیکن گوساله، پیاز قرمز، پنیر موزارلا",
      Price: G9Dictionary.Price("311").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    {
      Id: 115,
      Name: "پیتزا بیکن و قارچ",
      Ingredient: "بیکن گوساله، قارچ، سس خامه ترش، سس کرم قارچ، پنیر موزارلا",
      Price: G9Dictionary.Price("343").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    {
      Id: 26,
      Name: "پیتزا پپرونی",
      Ingredient: "سس گوجه فرنگی، پپرونی، پنیر موزارلا",
      Price: G9Dictionary.Price("320").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    {
      Id: 61,
      Name: "پیتزا سوسیس",
      Ingredient:
        "سس گوجه فرنگی، هات داگ 90 درصد، فلفل دلمه ای، درسینگ خردل، پنیر موزارلا",
      Price: G9Dictionary.Price("300").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    {
      Id: 21,
      Name: "پیتزا مرغ و هالوپینو",
      Ingredient:
        "سس هالوپینو، سس دلمه کبابی و بادام زمینی، مرغ، هالوپینو، پنیر موزارلا",
      Price: G9Dictionary.Price("290").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    {
      Id: 116,
      Name: "پیتزا مرغ و زیتون",
      Ingredient:
        "مرغ، زیتون سبز و سیاه، سس خامه ترش، پنیر فتا مزه‌دار شده، پنیر موزارلا",
      Price: G9Dictionary.Price("290").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    {
      Id: 22,
      Name: "پیتزا مرغ و پستو",
      Ingredient: "روغن زیتون فرابکر، مرغ، سس پستو، پنیر موزارلا",
      Price: G9Dictionary.Price("310").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },
    // {
    //   Id: 23,
    //   Name: "پیتزا میت بال",
    //   Ingredient: "سس گوجه فرنگی،گوشت چرخ کرده، کلم بروکلی، پنیر موزارلا",
    //   Price: G9Dictionary.Price("240").Translate(),
    //   MenuTypeInfo: PizzaMenuType,
    // },
    // {
    //   Id: 27,
    //   Name: "پیتزا فونگی(قارچ)",
    //   Ingredient: "سس قارچ، قارچ، لیمو، گردو، پنیر موزارلا",
    //   Price: G9Dictionary.Price("248").Translate(),
    //   MenuTypeInfo: PizzaMenuType,
    // },
    {
      Id: 28,
      Name: "پیتزا مارگاریتا",
      Ingredient: "سس گوجه فرنگی، پنیر موزارلا طبیعی، ریحان",
      Price: G9Dictionary.Price("210").Translate(),
      MenuTypeInfo: PizzaMenuType,
    },

    // ################################# SandwichesMenuType #################################
    {
      Id: 56,
      Name: "ساندویچ پولد بیف",
      Ingredient:
        "گوشت گوساله آرام پز، پیکل پیاز، دیپ پنیر چدار، نان برگر خمیرترش",
      Price: G9Dictionary.Price("396").Translate(),
      MenuTypeInfo: SandwichesMenuType,
    },
    {
      Id: 15,
      Name: "ساندویچ مرغ و پستو",
      Ingredient: "نان خمیرترش، سس پستو، مرغ",
      Price: G9Dictionary.Price("300").Translate(),
      MenuTypeInfo: SandwichesMenuType,
    },
    {
      Id: 72,
      Name: "ساندویچ اشنیتزل مرغ و هالوپینو",
      Ingredient: "نان خمیرترش، سس هالوپینو، مرغ سوخاری شده، سالاد کلم",
      Price: G9Dictionary.Price("313").Translate(),
      MenuTypeInfo: SandwichesMenuType,
    },
    {
      Id: 74,
      Name: "ساندویچ هات داگ",
      Ingredient:
        "نان مخصوص هات داگ، هات داگ 90%، دیپ پنیر، سالسا گوجه، پیکل خیار دست ساز",
      Price: G9Dictionary.Price("284").Translate(),
      MenuTypeInfo: SandwichesMenuType,
    },
    {
      Id: 124,
      Name: "ساندویچ بیکن",
      Ingredient:
        "نان خمیرترش ناپولیتن، بیکن گوساله، کاهو، گوجه فرنگی، سس مایونز چیلی دست ساز",
      Price: G9Dictionary.Price("313").Translate(),
      MenuTypeInfo: SandwichesMenuType,
    },
    {
      Id: 111,
      Name: "ساندویچ استیک",
      Ingredient:
        "نان خمیر‌ترش ناپولیتن، گوشت گوساله ورقه ای شده، فلفل دلمه، پیاز، دیپ پنیر چدار",
      Price: G9Dictionary.Price("343").Translate(),
      MenuTypeInfo: SandwichesMenuType,
    },
    {
      Id: 112,
      Name: "ساندویچ مرغ گریل لیمو فلفلی",
      Ingredient: "نان خمیر‌ترش ناپولیتن، مرغ، لیمو، سس لیمو فلفلی، سبزیجات",
      Price: G9Dictionary.Price("260").Translate(),
      MenuTypeInfo: SandwichesMenuType,
    },
    {
      Id: 127,
      Name: "ساندویچ سزار با مرغ گریل",
      Ingredient:
        "نان خمیر‌ترش ناپولیتن، مرغ گریل شده، کاهو، سس سزار، کروتان نان",
      Price: G9Dictionary.Price("295").Translate(),
      MenuTypeInfo: SandwichesMenuType,
    },
    {
      Id: 126,
      Name: "ساندویچ سزار با مرغ سوخاری",
      Ingredient:
        "نان خمیر‌ترش ناپولیتن، مرغ سوخاری شده، کاهو، سس سزار، کروتان نان",
      Price: G9Dictionary.Price("310").Translate(),
      MenuTypeInfo: SandwichesMenuType,
    },
    // {
    //   Id: 105,
    //   Name: "ساندویچ سرد بوقلمون",
    //   Ingredient:
    //     "نان فوکاچیا خمیرترش، سینه بوقلمون فرآوری شده، سس کرنبری(شیرین)، پنیر استراچیاتلا، سورِل، پسته",
    //   Price: G9Dictionary.Price("210").Translate(),
    //   MenuTypeInfo: SandwichesMenuType,
    // },
    // {
    //   Id: 106,
    //   Name: "ساندویچ سرد ژامبون",
    //   Ingredient:
    //     "نان فوکاچیا خمیرترش، ژامبون گوشت ۹۰٪، سالاد زیتون، کاهو، گوجه فرنگی، پیکل خیار، سس مایونز",
    //   Price: G9Dictionary.Price("170").Translate(),
    //   MenuTypeInfo: SandwichesMenuType,
    // },

    // ################################# BurgersMenuType #################################

    {
      Id: 18,
      Name: "چیز برگر",
      Ingredient:
        "گوشت گوساله چرخ شده(۱2۰/۲۴۰ گرم)، پنیر گودا، سس دست ساز، نان برگر خمیر ترش",
      Price: G9Dictionary.Price("402 | 279").Translate(),
      MenuTypeInfo: BurgersMenuType,
    },
    {
      Id: 17,
      Name: "برگر با مربا بیکن",
      Ingredient:
        "گوشت گوساله چرخ شده(۱2۰/۲۴۰ گرم)، پیاز کاراملی، بیکن گوساله، هالوپینو، سس دست ساز، نان برگر خمیرترش",
      Price: G9Dictionary.Price("418 | 309").Translate(),
      MenuTypeInfo: BurgersMenuType,
    },
    {
      Id: 113,
      Name: "چیمیچوری برگر",
      Ingredient:
        "گوشت گوساله چرخ شده(۱2۰/۲۴۰ گرم)،سس چیمیچوری دست ساز، پنیر پارمسان، نان برگر خمیرترش",
      Price: G9Dictionary.Price("418 | 309").Translate(),
      MenuTypeInfo: BurgersMenuType,
    },
    {
      Id: 114,
      Name: "برگر خردل پیاز",
      Ingredient:
        "گوشت گوساله چرخ شده(۱2۰/۲۴۰ گرم)،سس خردل، پیاز، پنیر چدار، نان برگر خمیرترش",
      Price: G9Dictionary.Price("418 | 309").Translate(),
      MenuTypeInfo: BurgersMenuType,
    },
    {
      Id: 85,
      Name: "برگر بیکن و قارچ",
      Ingredient:
        "گوشت گوساله چرخ شده(۱2۰/۲۴۰ گرم)، بیکن گوساله، قارچ، پنیر گودا، سس دست ساز، نان برگر خمیر ترش",
      Price: G9Dictionary.Price("450 | 351").Translate(),
      MenuTypeInfo: BurgersMenuType,
    },

    // ################################# DessertMenuType #################################
    {
      Id: 29,
      Name: "چیز کیک سن سباستین",
      Ingredient: "چیز کیک سن سباستین",
      Price: G9Dictionary.Price("98").Translate(),
      MenuTypeInfo: DessertMenuType,
    },
    {
      Id: 87,
      Name: "چیز کیک سن سباستین شکلاتی",
      Ingredient: "چیز کیک سن سباستین شکلاتی",
      Price: G9Dictionary.Price("110").Translate(),
      MenuTypeInfo: DessertMenuType,
    },
    {
      Id: 86,
      Name: "تیرامیسو",
      Ingredient: "تیرامیسو",
      Price: G9Dictionary.Price("98").Translate(),
      MenuTypeInfo: DessertMenuType,
    },
    {
      Id: 73,
      Name: "کوکی فندق و شکلات",
      Ingredient: "کوکی فندق و شکلات",
      Price: G9Dictionary.Price("60").Translate(),
      MenuTypeInfo: DessertMenuType,
    },

    // ################################# TeaAndHotDrinksMenuType #################################
    {
      Id: 67,
      Name: "ماچا لاته",
      Ingredient: "چای ماچا، شیر، سیروپ اسکاچ",
      Price: G9Dictionary.Price("130").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 36,
      Name: "شکلات داغ",
      Ingredient: "شکلات داغ",
      Price: G9Dictionary.Price("110").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 132,
      Name: "شکلات داغ و بادام زمینی",
      Ingredient: "پودر شکلات، شیر، بادام زمینی",
      Price: G9Dictionary.Price("125").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 35,
      Name: "چای عربی",
      Ingredient: "شیر تغلیظ شده، چای سیاه، ادویه",
      Price: G9Dictionary.Price("75").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 136,
      Name: "چای‌ کَرَک",
      Ingredient: "چای، شکر، زعفران، هل، بسکوئیت، شیر",
      Price: G9Dictionary.Price("120").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 34,
      Name: "چای تخصصی لاهیجان",
      Ingredient: "چای تخصصی لاهیجان",
      Price: G9Dictionary.Price("75").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 33,
      Name: "چای سیاه",
      Ingredient: "چای سیاه",
      Price: G9Dictionary.Price("50").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 92,
      Name: "چای کوهی",
      Ingredient: "چای کوهی",
      Price: G9Dictionary.Price("60").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 71,
      Name: "چای مراکشی",
      Ingredient: "چای سبز، نعنا، زنجبیل",
      Price: G9Dictionary.Price("60").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 32,
      Name: "دمنوش گل گاو زبان",
      Ingredient: "دمنوش گل گاو زبان",
      Price: G9Dictionary.Price("60").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 94,
      Name: "دمنوش آویشن",
      Ingredient: "دمنوش آویشن",
      Price: G9Dictionary.Price("60").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 95,
      Name: "دمنوش آرامش",
      Ingredient: "اسطوخودوس، سنبل الطیب",
      Price: G9Dictionary.Price("60").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 31,
      Name: "دمنوش زنجبیل به لیمو",
      Ingredient: "دمنوش زنجبیل به لیمو",
      Price: G9Dictionary.Price("60").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 30,
      Name: "دمنوش میوه های ترش",
      Ingredient: "دمنوش میوه های ترش",
      Price: G9Dictionary.Price("75").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 134,
      Name: "دمنوش میوه‌های شیرین",
      Ingredient: "انبه، آناناس، توت فرنگی، سیب، آلوئه ورا، پاپایا",
      Price: G9Dictionary.Price("75").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 96,
      Name: "دمنوش به دارچین",
      Ingredient: "دمنوش به دارچین",
      Price: G9Dictionary.Price("60").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 137,
      Name: "شیر ارده خرما",
      Ingredient: "شیر، ارده، شیره خرما",
      Price: G9Dictionary.Price("85").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },
    {
      Id: 133,
      Name: "لیوان شیر گرم",
      Ingredient: "لیوان شیر گرم",
      Price: G9Dictionary.Price("50").Translate(),
      MenuTypeInfo: TeaAndHotDrinksMenuType,
    },

    // ################################# ColdDrinkMenuType #################################
    // {
    //   Id: 38,
    //   Name: "اسموتی بری",
    //   Ingredient: "شاتوت، توت فرنگی، زرشک",
    //   Price: G9Dictionary.Price("105").Translate(),
    //   MenuTypeInfo: شیک موز توت فرنگی ,
    // },
    {
      Id: 39,
      Name: "تونیک زرشک",
      Ingredient: "سیروپ دست ساز زرشک، عصاره چای ترش، آب گازدار",
      Price: G9Dictionary.Price("86").Translate(),
      MenuTypeInfo: ColdDrinkMenuType,
    },
    {
      Id: 40,
      Name: "موهیتو",
      Ingredient: "لیمو، نعناع، سیروپ رام، آب گازدار",
      Price: G9Dictionary.Price("92").Translate(),
      MenuTypeInfo: ColdDrinkMenuType,
    },

    {
      Id: 119,
      Name: "لیموناد",
      Ingredient: "لیمو، سیروپ پوست مرکبات، آب گازدار",
      Price: G9Dictionary.Price("92").Translate(),
      MenuTypeInfo: ColdDrinkMenuType,
    },
    {
      Id: 68,
      Name: "لیموناد زنجبیل",
      Ingredient: "لیمو، سیروپ زنجبیل دست ساز، آب گازدار",
      Price: G9Dictionary.Price("92").Translate(),
      MenuTypeInfo: ColdDrinkMenuType,
    },
    // {
    //   Id: 120,
    //   Name: "لیموناد آبی",
    //   Ingredient:
    //     "لیمو، سیروپ پوست پرتقال و جلبک فیکوسیانین(بلوزست)، آب گازدار",
    //   Price: G9Dictionary.Price("96").Translate(),
    //   MenuTypeInfo: ColdDrinkMenuType,
    // },
    // {
    //   Id: 121,
    //   Name: "لیموناد صورتی",
    //   Ingredient: "لیمو، سیروپ رَزبری، آب گازدار",
    //   Price: G9Dictionary.Price("96").Translate(),
    //   MenuTypeInfo: ColdDrinkMenuType,
    // },
    // {
    //   Id: 97,
    //   Name: "سیب زنجبیل",
    //   Ingredient: "آب سیب، سیروپ زنجبیل دست ساز، آب گازدار",
    //   Price: G9Dictionary.Price("90").Translate(),
    //   MenuTypeInfo: ColdDrinkMenuType,
    // },
    {
      Id: 98,
      Name: "خاکشیر آلوئه ورا",
      Ingredient: "آلوئه ورا، گلاب، لیمو، سیروپ دست ساز زعفران",
      Price: G9Dictionary.Price("90").Translate(),
      MenuTypeInfo: ColdDrinkMenuType,
    },
    {
      Id: 99,
      Name: "شربت شیراز",
      Ingredient: "شربت بهار نارنج، گلاب، آب انگور، تخم شربتی",
      Price: G9Dictionary.Price("90").Translate(),
      MenuTypeInfo: ColdDrinkMenuType,
    },
    // {
    //   Id: 100,
    //   Name: "خیار سکنجبین",
    //   Ingredient: "خیار، لیمو، سکنجبین",
    //   Price: G9Dictionary.Price("85").Translate(),
    //   MenuTypeInfo: ColdDrinkMenuType,
    // },
    {
      Id: 101,
      Name: "شاتوت نارگیل",
      Ingredient: "شیر نارگیل، سیروپ دست ساز شاتوت، سیروپ دست ساز فندق",
      Price: G9Dictionary.Price("105").Translate(),
      MenuTypeInfo: ColdDrinkMenuType,
    },
    {
      Id: 64,
      Name: "ماچا شیر نارگیل",
      Ingredient: "چای ماچا، شیر نارگیل",
      Price: G9Dictionary.Price("135").Translate(),
      MenuTypeInfo: ColdDrinkMenuType,
    },
    {
      Id: 79,
      Name: "ماچا آفوگاتو",
      Ingredient: "چای ماچا، بستنی وانیلی",
      Price: G9Dictionary.Price("135").Translate(),
      MenuTypeInfo: ColdDrinkMenuType,
    },
    {
      Id: 64,
      Name: "ماچا نوفه",
      Ingredient: "چای ماچا، شیر، گلاب، وانیل",
      Price: G9Dictionary.Price("130").Translate(),
      MenuTypeInfo: ColdDrinkMenuType,
    },

    // {
    //   Id: 66,
    //   Name: "شیک شاتوت",
    //   Ingredient: "بستنی وانیلی، شیر، شاتوت",
    //   Price: G9Dictionary.Price("110").Translate(),
    //   MenuTypeInfo: ColdDrinkMenuType,
    // },

    // ################################# ShakeAndFrappe #################################

    {
      Id: 90,
      Name: "شیک موز توت فرنگی",
      Ingredient: "بستنی وانیلی، شیر تغلیظ شده، موز، توت فرنگی",
      Price: G9Dictionary.Price("190").Translate(),
      MenuTypeInfo: ShakeAndFrappeMenuType,
    },

    {
      Id: 82,
      Name: "شیک تیرامیسو",
      Ingredient: "بستنی وانیلی، شیر، تیرامیسو",
      Price: G9Dictionary.Price("165").Translate(),
      MenuTypeInfo: ShakeAndFrappeMenuType,
    },
    {
      Id: 63,
      Name: "شیک کوکی شکلات",
      Ingredient: "بستنی وانیلی، شیر، کوکی فندقی، شکلات واقعی",
      Price: G9Dictionary.Price("155").Translate(),
      MenuTypeInfo: ShakeAndFrappeMenuType,
    },
    {
      Id: 91,
      Name: "شیک شکلات نعناع",
      Ingredient: "بستنی وانیلی، شیر، شکلات واقعی، نعناع",
      Price: G9Dictionary.Price("150").Translate(),
      MenuTypeInfo: ShakeAndFrappeMenuType,
    },
    // {
    //   Id: 81,
    //   Name: "شیک موهیتو",
    //   Ingredient: "بستنی وانیلی، شیر، نعنا، لیمو",
    //   Price: G9Dictionary.Price("115").Translate(),
    //   MenuTypeInfo: ShakeAndFrappeMenuType,
    // },
    {
      Id: 80,
      Name: "شیک کره بادام زمینی",
      Ingredient: "بستنی وانیلی، شیر، کره بادام زمینی",
      Price: G9Dictionary.Price("140").Translate(),
      MenuTypeInfo: ShakeAndFrappeMenuType,
    },
    {
      Id: 80,
      Name: "شیک چوب شور",
      Ingredient: "بستنی وانیلی، شیر، چوب شور، سیروپ کارامل نمکی",
      Price: G9Dictionary.Price("130").Translate(),
      MenuTypeInfo: ShakeAndFrappeMenuType,
    },
    {
      Id: 54,
      Name: "فراپه کارامل",
      Ingredient: "بستنی وانیلی، یخ، قهوه سرد دم، کارامل",
      Price: G9Dictionary.Price("135").Translate(),
      MenuTypeInfo: ShakeAndFrappeMenuType,
    },
    {
      Id: 41,
      Name: "فراپه شکلات",
      Ingredient: "بستنی وانیلی، یخ، قهوه سرد دم، شکلات واقعی",
      Price: G9Dictionary.Price("135").Translate(),
      MenuTypeInfo: ShakeAndFrappeMenuType,
    },

    // ################################# CoffeeMenuType #################################
    {
      Id: 42,
      Name: "اسپرسو پنجاه",
      Ingredient: "ترکیب 50/50 قهوه روبوستا و قهوه عربیکا",
      Price: G9Dictionary.Price("58").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 42,
      Name: "اسپرسو روز",
      Ingredient: "قهوه عربیکا ترکیبی",
      Price: G9Dictionary.Price("70").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 42,
      Name: "اسپرسو تخصصی",
      Ingredient: "قهوه عربیکا تخصصی",
      Price: G9Dictionary.Price("95").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 43,
      Name: "آمریکانو",
      Ingredient: "آب، اسپرسو تخصصی / اسپرسو روز / اسپرسو پنجاه",
      Price: G9Dictionary.Price("100 | 75 | 63").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 44,
      Name: "کاپوچینو",
      Ingredient: "شیر، اسپرسو روز / اسپرسو پنجاه (180 میلی لیتر)",
      Price: G9Dictionary.Price("93 | 83").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 45,
      Name: "لاته",
      Ingredient: "شیر، اسپرسو روز / اسپرسو پنجاه (300 میلی لیتر)",
      Price: G9Dictionary.Price("95 | 85").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 135,
      Name: "لاته زعفران",
      Ingredient: "شیر، اسپرسو روز / اسپرسو پنجاه، سیروپ زعفران نوفه",
      Price: G9Dictionary.Price("115 | 105").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 46,
      Name: "موکا",
      Ingredient: "شیر، سیروپ شکلات دست ساز نوفه، اسپرسو روز / اسپرسو پنجاه",
      Price: G9Dictionary.Price("99 | 89").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 47,
      Name: "آفوگاتو",
      Ingredient: "قهوه سرد دم، بستنی وانیلی",
      Price: G9Dictionary.Price("95").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 48,
      Name: "قهوه سرد نوفه",
      Ingredient: "سیروپ دست ساز شاتوت، قهوه سرد دم، آب گازدار",
      Price: G9Dictionary.Price("95").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 49,
      Name: "قهوه دمی",
      Ingredient: "دم آوری قهوه تخصصی به صورت دستی",
      Price: G9Dictionary.Price("140").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 83,
      Name: "عصاره قهوه (گرم/سرد)",
      Ingredient: "عصاره قهوه، آب",
      Price: G9Dictionary.Price("58").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 88,
      Name: "تونیک آمریکانو ",
      Ingredient: "اسپرسو، آب گازدار، لیمو",
      Price: G9Dictionary.Price("94 | 84").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 89,
      Name: "آیس لاته گلاب",
      Ingredient: "اسپرسو، شیر، گلاب، وانیل",
      Price: G9Dictionary.Price("99 | 89").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },
    {
      Id: 128,
      Name: "آیس لاته شیر نارگیل",
      Ingredient: "اسپرسو، شیر نارگیل",
      Price: G9Dictionary.Price("99 | 89").Translate(),
      MenuTypeInfo: CoffeeMenuType,
    },

    // ################################# AdditiveMenuType #################################
    // {
    //   Id: 50,
    //   Name: "شیر نارگیل",
    //   Ingredient: "شیر نارگیل",
    //   Price: G9Dictionary.Price("10").Translate(),
    //   MenuTypeInfo: AdditiveMenuType,
    // },
    // {
    //   Id: 51,
    //   Name: "سیروپ",
    //   Ingredient: "سیروپ",
    //   Price: G9Dictionary.Price("15").Translate(),
    //   MenuTypeInfo: AdditiveMenuType,
    // },
    // {
    //   Id: 52,
    //   Name: "عسل",
    //   Ingredient: "عسل",
    //   Price: G9Dictionary.Price("30").Translate(),
    //   MenuTypeInfo: AdditiveMenuType,
    // },
    // {
    //   Id: 53,
    //   Name: "شیر بدون لاکتوز",
    //   Ingredient: "شیر بدون لاکتوز",
    //   Price: G9Dictionary.Price("10").Translate(),
    //   MenuTypeInfo: AdditiveMenuType,
    // },
    // {
    //   Id: 54,
    //   Name: "بستنی",
    //   Ingredient: "بستنی",
    //   Price: G9Dictionary.Price("15").Translate(),
    //   MenuTypeInfo: AdditiveMenuType,
    // },
  ];

  // Helper
  const StringIsNumber = (value: any) => isNaN(Number(value)) === false;

  // Turn enum into array
  const ToArray = (enumme: any) => {
    return Object.keys(enumme)
      .filter(StringIsNumber)
      .map((key) => enumme[key]);
  };

  const [filterType, setFilterType] = useState("filter-type-All");

  const [imageAddress, setImageAddress] = useState("");

  let lastHeaderGroupMenu = "";

  const { scrollYProgress } = useScroll();

  return (
    <>
      {/* <!-- ======= Menu Section ======= --> */}
      <section id="menu" className="menu section-bg">
        <motion.div
          className="progress-bar"
          style={{ scaleX: scrollYProgress }}
        />
        <div className="container" data-aos="fade-up">
          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="menu-flters">
                {ToArray(G9EMenuTypeInfo).map((s, index) => {
                  var generateFilterType = `filter-type-${s.toString()}`;
                  return (
                    <li
                      key={index}
                      data-filter={
                        s === G9EMenuTypeInfo.All
                          ? "*"
                          : `.filter-type-${s.toString()}`
                      }
                      onClick={() => {
                        setFilterType(generateFilterType);
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                      className={
                        filterType === generateFilterType ? "filter-active" : ""
                      }
                    >
                      {translatorByStringKey(s.toString())}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div
            className="row menu-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {MenuData.map((s, index) => {
              let generatedFilterType = `filter-type-${
                G9EMenuTypeInfo[s.MenuTypeInfo.MenuTypeEnum]
              }`;
              let newHeader = false;
              if (
                lastHeaderGroupMenu !==
                G9EMenuTypeInfo[s.MenuTypeInfo.MenuTypeEnum].toString()
              ) {
                lastHeaderGroupMenu =
                  G9EMenuTypeInfo[s.MenuTypeInfo.MenuTypeEnum].toString();
                newHeader = true;
              }
              //debugger
              return (
                <React.Fragment key={index}>
                  {newHeader ? (
                    <>
                      <div
                        className={`menu-group-header ${
                          filterType === `filter-type-All` ||
                          filterType === generatedFilterType
                            ? "ShowAnimation"
                            : "HideAnimation"
                        }`}
                      >
                        <label>
                          {translatorByStringKey(s.MenuTypeInfo.MenuTitle)}
                        </label>
                      </div>
                      {s.MenuTypeInfo.MenuExteraInfo
                        ? s.MenuTypeInfo.MenuExteraInfo.map((k) => {
                            return (
                              <div
                                className={`menu-group-header-ExteraInfo ${
                                  filterType === `filter-type-All` ||
                                  filterType === generatedFilterType
                                    ? "ShowAnimation"
                                    : "HideAnimation"
                                }`}
                              >
                                <label>{k}</label>
                              </div>
                            );
                          })
                        : ""}
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    className={`col-lg-6 menu-item ${generatedFilterType} ${
                      filterType === `filter-type-All` ||
                      filterType === generatedFilterType
                        ? "ShowAnimation"
                        : "HideAnimation"
                    }`}
                  >
                    <img
                      src={`assets/img/MenuImages/${s.Id}.jpg`}
                      onClick={() =>
                        setImageAddress(`assets/img/MenuImages/${s.Id}.jpg`)
                      }
                      className="menu-img"
                      alt=""
                    />
                    <div className="menu-content">
                      <a>{s.Name}</a>
                      <span>{s.Price}</span>
                    </div>
                    <div className="menu-ingredients">{s.Ingredient}</div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div>
          <a
            referrerPolicy="origin"
            target="_blank"
            href="https://trustseal.enamad.ir/?id=504175&Code=JFDI1tqzKmatOC57PNySXt9iyfykMgNb"
          >
            <img
              referrerPolicy="origin"
              src="https://trustseal.enamad.ir/logo.aspx?id=504175&Code=JFDI1tqzKmatOC57PNySXt9iyfykMgNb"
              alt=""
              style={{ cursor: "pointer" }}
              {...{ code: "JFDI1tqzKmatOC57PNySXt9iyfykMgNb" }}
            />
          </a>
        </div>
      </section>
      {imageAddress ? (
        <div className="G9ImagePreviewer" onClick={() => setImageAddress("")}>
          <img src={imageAddress} className="menu-img" alt="" />
        </div>
      ) : (
        ""
      )}
      <div id="phone-number" key="PhoneNumber">
        <a href="tel:0563193">
          <i className="fa fa-phone"></i>
          <label>056-3193</label>
        </a>
      </div>
    </>
  );
}
